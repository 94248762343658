.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
  &:focus {
    @apply ring-0;
  }
}

// Pulsing animation
@keyframes pulseLoop {
  0% {
    opacity: 0;
    transform: scale(0.1) translateZ(0);
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(2) translateZ(0);
  }
}
.radiate-pulse {
  transform: scale(0.1);
  opacity: 0;
  transform-origin: center;
  animation: pulseLoop 8000ms linear infinite;
}
.radiate-pulse-1 {
  animation-delay: -2000ms;
}
.radiate-pulse-2 {
  animation-delay: -4000ms;
}
.radiate-pulse-3 {
  animation-delay: -6000ms;
}
