@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Inter:wght@400;500;600;700;800;900&display=fallback");

@tailwind base;
@tailwind components;

@import "react-vertical-timeline-component/style.min.css";
.vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 4px #2f2f4d, inset 0 2px 0 rgb(0 0 0 / 8%),
    0 3px 0 4px rgb(0 0 0 / 5%);
  box-shadow: 0 0 0 4px #2f2f4d, inset 0 2px 0 rgb(0 0 0 / 8%),
    0 3px 0 4px rgb(0 0 0 / 5%);
}

.vertical-timeline-element-content {
  -webkit-box-shadow: 0 3px 0 #2f2f4d;
  box-shadow: 0 3px 0 #2f2f4d;
}
.vertical-timeline-element-content .vertical-timeline-element-date,
.vertical-timeline-element-content p {
  font-size: unset;
  font-size: unset;
  font-weight: unset;
}

// Additional styles
@import "additional-styles/utility-patterns.scss";
@import "additional-styles/range-slider.scss";
@import "additional-styles/toggle-switch.scss";
@import "additional-styles/theme.scss";

@tailwind utilities;

// Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive
@layer utilities {
  .rtl {
    direction: rtl;
  }
}

@layer components {
  .hamburger {
    @apply relative flex items-center justify-center w-6 h-6 cursor-pointer transition-transform duration-300;
  }

  .hamburger svg rect {
    @apply transition-all duration-300 origin-center;
  }

  /* Active state for the first line */
  .hamburger.active svg rect:nth-child(1) {
    @apply translate-y-[6px] rotate-45;
  }

  /* Active state for the second line (hide) */
  .hamburger.active svg rect:nth-child(2) {
    @apply opacity-0;
  }

  /* Active state for the third line */
  .hamburger.active svg rect:nth-child(3) {
    @apply -translate-y-[6px] -rotate-45;
  }
}

// See Alpine.js: https://github.com/alpinejs/alpine#x-cloak
[x-cloak] {
  display: none;
}

// AOS styles
$aos-distance: 10px;
@import "node_modules/aos/src/sass/aos.scss";

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  /* border: 0.25rem solid #848aff; */
  -webkit-text-fill-color: #848aff;
  -webkit-box-shadow: 0 0 0px 1000px #1d1f31 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.Typist .Cursor {
  display: inline-block;
}
.Typist .Cursor--blinking {
  opacity: 1;
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.player-wrapper {
  position: relative;
  // padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.react-player-video * {
  width: 100%;
  border-radius: 0.375rem 0.375rem 0 0;
}

// .aos-animate {
//   transition-property: all;
//   transition-duration: 1s;
// }

[data-aos="fade-out"] {
  opacity: 0;
  transition-property: opacity;

  &.aos-animate {
    opacity: 1;
  }
}